import React, { useState, useRef, useEffect } from 'react'

/**
 * A multiple-select component styled with DaisyUI v4.
 *
 * Props:
 *   options       : Array of objects { value, label }, e.g. [{ value: 'js', label: 'JavaScript' }]
 *   placeholder   : String displayed when no items are selected.
 */
const MultipleSelectInput = ({
    options = [],
    placeholder = 'Select options...',
    max = 3,
    selected = [],
    onChange = () => {},
    label,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState(
        options.filter((option) => {
            return selected.includes(option.value)
        })
    )
    const dropdownRef = useRef(null)

    // Close dropdown if user clicks outside of the component
    useEffect(() => {
        function handleClickOutside(event) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () =>
            document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    useEffect(() => {
        onChange(selectedOptions.map((option) => option.value))
    }, [selectedOptions])

    const toggleDropdown = (evt) => {
        evt.preventDefault()
        setIsOpen((prev) => !prev)
    }

    const isSelected = (option) => {
        return selectedOptions.some((item) => item.value === option.value)
    }

    const handleOptionClick = (option) => {
        // If it's already selected, remove it; otherwise, add it.
        setSelectedOptions((prev) => {
            if (isSelected(option)) {
                return prev.filter((item) => item.value !== option.value)
            }
            return [...prev, option]
        })
    }

    const handleRemove = (e, option) => {
        //e.stopPropagation() // Prevent toggleDropdown when removing
        setSelectedOptions((prev) =>
            prev.filter((item) => item.value !== option.value)
        )
    }

    return (
        <div ref={dropdownRef} className="w-full relative">
            <label
                className="form-control"
                onClick={(evt) => toggleDropdown(evt)}>
                {/* Selected Items Input Box */}
                <div className="label">
                    <span className="label-text">{label}</span>
                </div>
                <div className="select select-bordered gap-4 flex items-center">
                    {selectedOptions.length === 0 ? (
                        <span className="text-base-content/50 text-sm">
                            {placeholder}
                        </span>
                    ) : (
                        selectedOptions.map((option, i) => {
                            return i < max ? (
                                <div
                                    key={option.value}
                                    className={`badge badge-primary gap-4 items-center p-4 `}
                                    onClick={(e) => handleRemove(e, option)}>
                                    {option.label}
                                    <button className="btn btn-xs btn-ghost p-0">
                                        <span>✕</span>
                                    </button>
                                </div>
                            ) : i === max ? (
                                <div className="text-lg">...</div>
                            ) : null
                        })
                    )}
                </div>
            </label>

            {/* Dropdown Menu */}
            {isOpen && (
                <div className="absolute z-10 mt-1 w-full shadow bg-base-100 border border-base-300 rounded-md max-w-sm">
                    <ul className="menu menu-compact p-2">
                        {options.map((option) => (
                            <li key={option.value}>
                                <div
                                    onClick={() => handleOptionClick(option)}
                                    className={`justify-between flex items-center p-2 rounded-md cursor-pointer `}>
                                    <span>{option.label}</span>
                                    {isSelected(option) ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-5 h-5 text-gray-500 mr-3">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="m4.5 12.75 6 6 9-13.5"
                                            />
                                        </svg>
                                    ) : null}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}

export default MultipleSelectInput
