import React from 'react'
import { Trans } from 'gatsby-plugin-react-i18next'

const Select = ({
    label,
    altLabel,
    altLabelBottomLeft,
    altLabelBottomRight,
    options,
    onChange,
    error,
    defaultValue = '',
    ...props
}) => {
    return (
        <label className="form-control w-full ">
            <div className="label">
                <span className="label-text">
                    <Trans>{label}</Trans>
                </span>
                <span className="label-text-alt">
                    <Trans>{altLabel}</Trans>
                </span>
            </div>
            <select
                className={`select select-bordered ${
                    error ? 'select-error' : ''
                }`}
                placeholder="Select an option"
                onChange={(evt) => onChange(evt.target.value)}
                defaultValue={defaultValue}>
                <option
                    key="disabled_select"
                    value=""
                    disabled={true}
                    selected={true}>
                    <Trans>Sélectionnez</Trans>
                </option>
                {options.map((o) => {
                    return (
                        <option
                            key={o.value}
                            value={o.value}
                            disabled={o.disabled}>
                            <Trans>{o.label}</Trans>
                        </option>
                    )
                })}
            </select>
            <div className="label">
                <span className="label-text-alt">
                    <Trans>{altLabelBottomLeft}</Trans>
                </span>
                <span className="label-text-alt">
                    <Trans>{altLabelBottomRight}</Trans>
                </span>
            </div>
        </label>
    )
}

export default Select
