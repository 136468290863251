import React from 'react'
import TextField from '../../components/TextField/new'
import { MenuItem, FormControlLabel, Checkbox } from '@material-ui/core'
import Select from '../../components/Select/new'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import regions, { regionsON } from '../../constants/regions'
import MultipleSelect from '../../components/MultiSelect'

const nonDigits = new RegExp(/[^0-9\-\s]/g)

const maskPhoneNumber = (phoneNumber) => phoneNumber.replace(nonDigits, '')

const Info = ({ saveFieldState, fields }) => {
    const {
        clientName,
        email,
        phoneNumber,
        province,
        region,
        acceptDistance,
        acceptEnglishLawyer,
        bestTimeToContact,
    } = fields
    const { t, i18n } = useTranslation()
    const language = i18n.language
    const regionsValues = province.value === 'ON' ? regionsON : regions

    return (
        <div style={{ width: '100%' }}>
            <div item xs={12} sm={10} style={{ margin: '0 auto' }}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-1">
                    <div item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            helperText={t('validName')}
                            id="clientName"
                            name="clientName"
                            className="textInput"
                            label={t('Nom complet')}
                            placeholder={t('Nom complet')}
                            autoComplete="name"
                            onChange={saveFieldState}
                            {...clientName}
                        />
                    </div>
                    <div item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            helperText={t('validEmail')}
                            id="email"
                            name="email"
                            className="textInput"
                            label={t('Courriel')}
                            placeholder={t('Courriel')}
                            autoComplete="email"
                            type="email"
                            onChange={saveFieldState}
                            {...email}
                        />
                    </div>
                    <div item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            helperText={t('validPhoneNumber')}
                            id="phoneNumber"
                            name="phoneNumber"
                            className="textInput"
                            label={t('Numéro de téléphone')}
                            placeholder={t('Numéro de téléphone')}
                            autoComplete="tel-national"
                            onChange={saveFieldState}
                            maskingFn={maskPhoneNumber}
                            {...phoneNumber}
                        />
                    </div>
                    <div item xs={12} sm={6} style={{ textAlign: 'left' }}>
                        <Select
                            select
                            fullWidth
                            helperText={t(
                                'Vous devez sélectionner une province.'
                            )}
                            id="province"
                            name="province"
                            className="textInput"
                            label={t('Province')}
                            placeholder={t('Province')}
                            autoComplete="off"
                            onChange={(val) =>
                                saveFieldState('province', val, true)
                            }
                            {...province}
                            value={province.value}
                            options={[
                                {
                                    label: 'Québec',
                                    value: 'QC',
                                    disabled: false,
                                },
                                {
                                    label: 'Ontario',
                                    value: 'ON',
                                    disabled: false,
                                },
                            ]}></Select>
                    </div>
                    <div
                        className="md:col-span-2"
                        item
                        xs={12}
                        style={{ textAlign: 'left' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={acceptDistance.value}
                                    onChange={() => {
                                        saveFieldState(
                                            'acceptDistance',
                                            !acceptDistance.value,
                                            true
                                        )
                                    }}
                                    name="acceptDistance"
                                />
                            }
                            label={
                                <span>
                                    <strong>
                                        <Trans>
                                            J'accepte d'être servi à distance,
                                            même à l'extérieur de ma région
                                        </Trans>
                                    </strong>{' '}
                                    <br />
                                    <small>{t('optionIncreaseChances')}</small>
                                </span>
                            }
                        />
                    </div>

                    {province.value !== '' ? (
                        <div
                            className="md:col-span-2"
                            item
                            xs={12}
                            style={{ textAlign: 'left' }}>
                            <Select
                                select
                                fullWidth
                                helperText={t(
                                    'Vous devez sélectionner une région'
                                )}
                                id="region"
                                name="region"
                                className="textInput"
                                style={{ maxWidth: '88vw' }}
                                label={
                                    acceptDistance.value === true
                                        ? t("Région souhaitée pour l'avocat")
                                        : t('Votre région')
                                }
                                autoComplete="off"
                                onChange={(val) =>
                                    saveFieldState('region', val, true)
                                }
                                options={regionsValues.map((item) => ({
                                    value: item.name,
                                    label: item.label,
                                    disabled: item.disabled,
                                }))}
                                {...region}></Select>
                        </div>
                    ) : null}
                    {province.value === 'ON' && language === 'fr' ? (
                        <div
                            className="md:col-span-2"
                            item
                            xs={12}
                            style={{ textAlign: 'left' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={acceptEnglishLawyer.value}
                                        onChange={() => {
                                            saveFieldState(
                                                'acceptEnglishLawyer',
                                                !acceptEnglishLawyer.value,
                                                true
                                            )
                                        }}
                                        name="acceptEnglishLawyer"
                                    />
                                }
                                label={
                                    <span>
                                        <strong>
                                            <Trans>
                                                J’accepte d'être mis en relation
                                                avec un avocat anglophone
                                            </Trans>
                                            .
                                        </strong>{' '}
                                        <br />
                                        <small>
                                            {t('optionIncreaseChances')}
                                        </small>
                                    </span>
                                }
                            />
                        </div>
                    ) : null}
                    <div
                        className="md:col-span-2"
                        item
                        xs={12}
                        style={{ textAlign: 'left' }}>
                        <MultipleSelect
                            id="bestTimeToContact"
                            name="bestTimeToContact"
                            className="textInput"
                            label={t('Meilleur moment pour vous joindre')}
                            placeholder={t(
                                'Sélectionnez un ou plusieurs moments'
                            )}
                            autoComplete="off"
                            onChange={(values) =>
                                saveFieldState(
                                    'bestTimeToContact',
                                    values,
                                    true
                                )
                            }
                            selected={bestTimeToContact.value}
                            options={[
                                {
                                    label: t('Dès que possible'),
                                    value: 'asap',
                                },
                                {
                                    label: t('Matin'),
                                    value: 'morning',
                                },
                                {
                                    label: t('Midi'),
                                    value: 'noon',
                                },
                                {
                                    label: t('Après-midi'),
                                    value: 'afternoon',
                                },
                                {
                                    label: t('Soir'),
                                    value: 'evening',
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Info
