import React from 'react'
import { validateField } from '../../utils/reactFormValidation'
const Textfield = (
    {
        onChange,
        label,
        altLabel,
        altLabelBottomLeft,
        altLabelBottomRight,
        type = 'text',
        name,
        placeholder = '',
        valid,
        helperText,
        defaultValue,
    },
    props
) => {
    const handleChange = (evt) => {
        if (helperText) {
            const { name } = evt.target
            let { value } = evt.target
            const { regex, optional } = props
            if (props.maskingFn) {
                value = props.maskingFn(value)
            }
            const valid = validateField(value, regex, optional)

            onChange(name, value, valid)
        } else {
            onChange(evt)
        }
    }
    return (
        <label className="form-control w-full max-w-full ">
            <div className="label">
                <span
                    className={`label-text ${
                        props.valid === false ? 'text-error' : ''
                    }`}>
                    {label}
                </span>
                <span className="label-text-alt">{altLabel}</span>
            </div>
            {type === 'textarea' ? (
                <textarea
                    className="textarea textarea-bordered w-full"
                    onChange={handleChange}
                    name={name}
                    placeholder={placeholder || label}
                />
            ) : (
                <input
                    className="input input-bordered w-full"
                    onChange={handleChange}
                    type={type}
                    name={name}
                    placeholder={placeholder || label}
                    defaultValue={defaultValue}
                />
            )}

            <div className="label">
                <span
                    className={`label-text-alt ${
                        props.valid === false ? 'text-error' : ''
                    }`}>
                    {props.valid === false
                        ? props.helperText
                        : altLabelBottomLeft}
                </span>
                <span className="label-text-alt">{altLabelBottomRight}</span>
            </div>
            {valid === false ? (
                <span className="text-error">{helperText}</span>
            ) : null}
        </label>
    )
}

export default Textfield
