import React from 'react'

import styled from '@emotion/styled'

import Button from '../Button'
import arrow from '../../../static/06-2020/Jurigo-11.svg'
import './buttonWithArrow.css'

// prettier-ignore
const Container = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    margin: 0 20px 12px;
    ${({ fullWidth }) => !fullWidth && 
    `@media (max-width: 767px) {
        justify-content: flex-start;
        button {
            font-size: 12px;
            margin: 0;
            width: 92%;
        }
    }`}
    && {
        img {
            margin: ${(props) =>
                !props.fullWidth ? `0 -2.5rem -3rem 0` : `inherit`};
            position: absolute;
            right: 0px;
            width: 30px;
            bottom: 70px;
            z-index: 10;
            @media (max-width: 767px) {
                margin: ${(props) =>
                    !props.fullWidth ? `0px -0.6rem -3rem 0px` : `inherit`};
            }
        }
    }
`
const Img = styled.img``

const ButtonWithArrow = ({ onClick, children, fullWidth, disabled }) => (
    <Container fullWidth={fullWidth} className="button-with-arrow">
        <button
            className="button-with-arrow btn-lg btn btn-secondary text-white rounded-full text-2xl md:text-3xl p-10 flex-nowrap uppercase mx-4"
            type="submit"
            variant="contained"
            color="primary"
            onClick={onClick}
            disabled={disabled}>
            {children}
        </button>
        <Img src={arrow} fullWidth={fullWidth} alt="" />
    </Container>
)

export default ButtonWithArrow
