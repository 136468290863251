import {
    LockOpen,
    Business,
    Cached,
    Description,
    People,
    EmojiPeople,
    CreditCard,
    FolderOpen,
    House,
    Apartment,
    FlightLand,
    AccountBalance,
    Work,
    AssignmentLate,
    Assignment,
    Category,
} from '@material-ui/icons'

const SMALL = 'smallMandate'
const COMM = 'commercial'
const LEGAL_AID = 'juridicalHelp'
const MP = 'normal'

export const lawyerTypesON = [
    {
        name: 'on-admin',
        label: 'Administratif',
        icon: Description,
        defaultBlockType: SMALL,
        coins: {
            commercial: 50,
            normal: 38,
            smallMandate: 25,
            juridicalHelp: 20,
        },
        children: [
            {
                name: 'on-divisional',
                label: 'Cour divisionnaire',
            },
            {
                name: 'on-social-help',
                label: 'Aide sociale / chômage',
            },
            {
                name: 'on-aivo',
                label: 'AIVO',
            },
            {
                name: 'on-employment',
                label: 'Employment',
                coins: {
                    normal: 38,
                },
            },
            {
                name: 'on-pensions',
                label: 'Rentes / pensions',
            },
            {
                name: 'on-violence',
                label: 'Violence conjugale',
                defaultBlockType: MP,
            },
        ],
    },
    {
        name: 'on-business',
        label: 'Affaires',
        icon: Business,
        defaultBlockType: COMM,
        coins: {
            commercial: 50,
            normal: 41,
            smallMandate: 25,
            juridicalHelp: 20,
        },
        increases: {
            regions: ['ON-GTA'],
            multiplier: 1.1,
            appliesToChildren: true,
        },
        distanceDefault: true,
        children: [
            {
                name: 'on-business-corp',
                label: 'Corporatif',
                disabled: true,
            },
            {
                name: 'on-corp-start',
                label: 'Corporatif (démarrage)',
            },
            {
                name: 'on-corp-franchise',
                label: 'Corporatif (franchisage)',
            },
            {
                name: 'on-corp-ma',
                label: 'Corporatif (fusion/achat/vente/transfert)',
            },
            {
                name: 'on-corp-npo',
                label: 'Corporatif (OBNL)',
            },
            {
                name: 'on-corp-reorg',
                label: 'Corporatif (réorganisation)',
            },
            {
                name: 'on-business-litige',
                label: 'Litige',
                disabled: true,
            },
            {
                name: 'on-litige-action',
                label: 'Litige (action sur compte)',
            },
            {
                name: 'on-litige-competition',
                label: 'Litige (concurrence déloyale)',
            },
            {
                name: 'on-litige-shareholders',
                label: 'Litige (entre actionnaires)',
            },
            {
                name: 'on-litige-post-transac',
                label: 'Litige (post-transaction)',
            },
            {
                name: 'on-litige-other',
                label: 'Litige (autre)',
            },
            {
                name: 'on-commercial',
                label: 'Commercial (contractuel)',
            },
            {
                name: 'on-arbitrage',
                label: 'Arbitrage et médiation commerciale',
            },
            {
                name: 'on-ip',
                label: 'Propriété intellectuelle',
            },
            {
                name: 'on-corp-minutes',
                label: 'Livres des minutes',
            },
        ],
    },
    {
        name: 'on-criminal',
        label: 'Criminel et pénal',
        jh: true,
        icon: LockOpen,
        defaultBlockType: MP,
        coins: {
            commercial: 50,
            normal: 57,
            smallMandate: 24,
            juridicalHelp: 25,
        },
        certRequired: true,
        children: [
            {
                name: 'on-criminal-crim',
                label: 'Criminel',
                disabled: true,
            },
            {
                name: 'on-crim-firearm',
                label: 'Criminel (arme à feu)',
                coins: {
                    normal: 50,
                },
            },
            {
                name: 'on-crim-person',
                label: 'Criminel (contre la personne et la réputation)',
            },
            {
                name: 'on-crim-prop',
                label: 'Criminel (contre les droits de propriété)',
            },
            {
                name: 'on-crim-fraud',
                label: 'Criminel (fraude)',
            },
            {
                name: 'on-crim-youth',
                label: 'Criminel (jeune contrevenant)',
            },
            {
                name: 'on-crim-transport',
                label: 'Criminel (moyen de transport)',
            },
            {
                name: 'on-crim-waiver',
                label: 'Criminel (pardon/waiver)',
                defaultBlockType: SMALL,
            },
            {
                name: 'on-crim-sex',
                label: 'Criminel (sexuel)',
            },
            {
                name: 'on-crim-stup',
                label: 'Criminel (stupéfiant)',
            },
            {
                name: 'on-criminal-penal',
                label: 'Pénal',
                disabled: true,
            },
            {
                name: 'on-penal-hunting',
                label: 'Pénal (chasse)',
                defaultBlockType: SMALL,
                distanceDefault: true,
            },
            {
                name: 'on-penal-enterprise',
                label: 'Pénal (entreprise)',
                defaultBlockType: SMALL,
                distanceDefault: true,
            },
            {
                name: 'on-penal-environment',
                label: 'Pénal (environnement)',
                defaultBlockType: SMALL,
                distanceDefault: true,
            },
            {
                name: 'on-penal-road',
                label: 'Pénal (infraction de la route)',
                defaultBlockType: SMALL,
                distanceDefault: true,
            },
            {
                name: 'on-penal-other',
                label: 'Infraction pénal (autre)',
                defaultBlockType: SMALL,
                distanceDefault: true,
            },
            {
                name: 'on-theft-5000-less',
                label: 'Vol de 5000$ ou moins',
                paralegal: true,
            },
            {
                name: 'on-crim-harassment',
                label: 'Harcèlement criminel',
            },
            {
                name: 'on-crim-assault',
                label: 'Voies de fait',
                paralegal: true,
            },
        ],
    },
    {
        name: 'on-rights',
        label: 'Droits et libertés',
        jh: true,
        icon: EmojiPeople,
        defaultBlockType: MP,
        coins: {
            commercial: 50,
            normal: 38,
            smallMandate: 25,
            juridicalHelp: 20,
        },
        children: [
            {
                name: 'on-rights-liberty',
                label: 'Droits et libertés',
                disabled: true,
            },
            {
                name: 'on-rights-native',
                label: 'Autochtone',
                defaultBlockType: SMALL,
                certRequired: true,
            },
            {
                name: 'on-rights-constitution',
                label: 'Constitution et chartes',
            },
            {
                name: 'on-rights-protection',
                label: 'Personnes vulnérables (curatelle, tutelle, conseil au majeur)',
                defaultBlockType: SMALL,
                certRequired: true,
            },
            {
                name: 'on-rights-care',
                label: 'Soin et garde en établissement',
                defaultBlockType: SMALL,
                certRequired: true,
            },
            {
                name: 'on-rights-tribunal',
                label: `Tribunal des droits de la personne de l’Ontario`,
                paralegal: true,
                coins: {
                    normal: 27,
                },
            },
        ],
    },
    {
        name: 'on-bankruptcy',
        label: 'Faillite et insolvabilité',
        icon: CreditCard,
        defaultBlockType: MP,
        distanceDefault: true,
        coins: {
            commercial: 50,
            normal: 41,
            smallMandate: 25,
            juridicalHelp: 20,
        },
        children: [
            {
                name: 'on-bankruptcy-enterprise',
                label: 'Entreprise',
                defaultBlockType: COMM,
            },
            {
                name: 'on-bankruptcy-person',
                label: 'Particulier',
                defaultBlockType: MP,
            },
        ],
    },
    {
        name: 'on-family',
        label: 'Famille et jeunesse',
        jh: true,
        icon: People,
        certRequired: true,
        defaultBlockType: MP,
        coins: {
            commercial: 50,
            normal: 38,
            smallMandate: 25,
            juridicalHelp: 20,
        },
        increases: {
            regions: ['ON-GTA'],
            multiplier: 1.1,
            appliesToChildren: true,
        },
        children: [
            {
                name: 'on-family-family',
                label: 'Divorce/Séparation',
                paralegal: true,
            },
            {
                name: 'on-family-youth',
                label: 'Jeunesse',
                defaultBlockType: SMALL,
            },
            {
                name: 'on-family-mediation',
                label: 'Médiation familiale',
                defaultBlockType: SMALL,
                distanceDefault: true,
            },
            {
                name: 'on-violence',
                label: 'Violence conjugale',
                certRequired: true,
            },
            {
                name: 'on-family-contested',
                label: 'Dossiers non-contestés',
                paralegal: true,
                distanceDefault: true,
                defaultBlockType: SMALL,
            },
            {
                name: 'on-family-child-spousal-over-150k',
                label: 'Pension alimentaire (revenu de plus de 150 000$)',
            },
            {
                name: 'on-family-child-spousal-under-150k',
                label: 'Pension alimentaire (revenu de 150 000$ et moins)',
                paralegal: true,
            },
            {
                name: 'on-family-custody',
                label: 'Garde pour enfant',
            },
        ],
    },
    {
        name: 'on-fiscal',
        label: 'Fiscalité et taxes',
        icon: FolderOpen,
        defaultBlockType: MP,
        distanceDefault: true,
        coins: {
            commercial: 50,
            normal: 41,
            smallMandate: 25,
            juridicalHelp: 20,
        },
        children: [
            {
                name: 'on-fiscal-enterprise',
                label: 'Entreprise',
                defaultBlockType: COMM,
            },
            {
                name: 'on-fiscal-person',
                label: 'Particulier',
                defaultBlockType: MP,
            },
        ],
    },
    {
        name: 'on-realestate',
        label: 'Immobilier',
        icon: House,
        defaultBlockType: MP,
        coins: {
            commercial: 50,
            normal: 41,
            smallMandate: 25,
            juridicalHelp: 20,
        },
        increases: {
            regions: ['ON-GTA'],
            multiplier: 1.1,
            appliesToChildren: true,
        },
        children: [
            {
                name: 'on-construction',
                label: 'Construction',
                distanceDefault: true,
            },
            {
                name: 'on-condo',
                label: 'Copropriété',
                distanceDefault: true,
            },
            {
                name: 'on-realestate-title',
                label: 'Titre, bornage, servitude, etc',
                distanceDefault: true,
            },
            {
                name: 'on-realestate-vice',
                label: 'Vice caché',
                distanceDefault: true,
            },
            {
                name: 'on-realestate-transaction',
                label: 'Achat/Vente',
                defaultBlockType: SMALL,
            },
        ],
    },
    {
        name: 'on-rental',
        label: 'Logement',
        icon: Apartment,
        jh: true,
        defaultBlockType: MP,
        distanceDefault: true,
        coins: {
            commercial: 50,
            normal: 27,
            smallMandate: 25,
            juridicalHelp: 20,
        },
        paralegal: true,
        children: [
            {
                name: 'on-rental-landlord',
                label: 'Propriétaire',
                paralegal: true,
                coins: {
                    normal: 30,
                },
            },
            {
                name: 'on-rental-renter',
                label: 'Locataire',
                paralegal: true,
            },
        ],
    },
    {
        name: 'on-immigration',
        label: 'Immigration et citoyenneté',
        icon: FlightLand,
        paralegal: true,
        defaultBlockType: MP,
        distanceDefault: true,
        coins: {
            commercial: 50,
            normal: 38,
            smallMandate: 25,
            juridicalHelp: 20,
        },
        children: [
            {
                name: 'on-immigration-sponsorship',
                label: 'Parrainage',
                paralegal: true,
            },
            {
                name: 'on-immigration-work-permit',
                label: `Permis d'étude et de travail`,
                paralegal: true,
            },
            {
                name: 'on-immigration-refugee',
                label: 'Réfugié',
                paralegal: true,
                certRequired: true,
                coins: {
                    normal: 33,
                },
            },
            {
                name: 'on-immigration-permanent',
                label: 'Résidence permanente',
                paralegal: true,
            },
            {
                name: 'on-immigration-contest',
                label: 'Contestation (contrôle et appel)',
                paralegal: true,
            },
            {
                name: 'on-immigration-usa',
                label: 'USA',
            },
            {
                name: 'on-immigration-other',
                label: 'Autre',
                paralegal: true,
            },
        ],
    },
    {
        name: 'on-municipal',
        label: 'Municipal',
        icon: AccountBalance,
        children: null,
        topLevel: true,
        defaultBlockType: MP,
        coins: {
            commercial: 50,
            normal: 41,
            smallMandate: 25,
            juridicalHelp: 20,
        },
        increases: {
            regions: ['ON-GTA'],
            multiplier: 1.1,
            appliesToChildren: true,
        },
    },
    {
        name: 'on-responsability',
        label: 'Responsabilité civile',
        icon: AssignmentLate,
        defaultBlockType: MP,
        distanceDefault: true,
        coins: {
            commercial: 50,
            normal: 41,
            smallMandate: 25,
            juridicalHelp: 20,
        },
        children: [
            {
                name: 'on-responsability-general',
                label: 'Générale',
            },
            {
                name: 'on-responsability-slander',
                label: 'Diffamation / atteinte à la réputation',
            },
            {
                name: 'on-responsability-injury',
                label: 'Blessure corporelle',
            },
            {
                name: 'on-responsability-medical',
                label: 'Erreur médicale',
            },
            {
                name: 'on-responsability-pro',
                label: 'Professionnelle et disciplinaire',
            },
            {
                name: 'on-responsability-neighborhood',
                label: 'Trouble de voisinage',
            },
            {
                name: 'on-responsability-sabia',
                label: `Demandes d'indemnités d'accident couvertes par Loi sur les Assurances`,
                paralegal: true,
                coins: {
                    normal: 27,
                },
            },
        ],
    },
    {
        name: 'on-succession',
        label: 'Succession',
        children: null,
        topLevel: true,
        icon: Cached,
        defaultBlockType: SMALL,
        coins: {
            commercial: 50,
            normal: 38,
            smallMandate: 25,
            juridicalHelp: 20,
        },
        increases: {
            regions: ['ON-GTA'],
            multiplier: 1.1,
            appliesToChildren: true,
        },
        children: [
            {
                name: 'on-estate-admin',
                label: 'Administration de succession',
            },
            {
                name: 'on-estate-litigation',
                label: 'Litige successoral',
                defaultBlockType: MP,
            },
            {
                name: 'on-estate-wills',
                label: 'Testaments',
            },
        ],
    },
    {
        name: 'on-work',
        label: 'Travail',
        icon: Work,
        defaultBlockType: MP,
        distanceDefault: true,
        coins: {
            commercial: 50,
            normal: 41,
            smallMandate: 25,
            juridicalHelp: 20,
        },
        children: [
            {
                name: 'on-work-employee',
                label: 'Harcèlement / pratiques interdites',
                coins: {
                    normal: 27,
                },
                distanceDefault: false,
            },
            {
                name: 'on-work-employer',
                label: 'Employeur',
            },
            {
                name: 'on-wsib',
                label: 'WSIB',
                paralegal: true,
                coins: {
                    normal: 27,
                },
            },
            {
                name: 'on-work-termination',
                label: 'Congédiement',
            },
        ],
    },
    {
        name: 'on-civil',
        label: 'Civil (général)',
        icon: Assignment,
        defaultBlockType: MP,
        coins: {
            commercial: 50,
            normal: 41,
            smallMandate: 25,
            juridicalHelp: 20,
        },
        increases: {
            regions: ['ON-GTA'],
            multiplier: 1.1,
            appliesToChildren: true,
        },
        children: [
            {
                name: 'on-colAction',
                label: 'Action collective',
            },
            {
                name: 'on-civil-account',
                label: 'Action sur compte',
            },
            {
                name: 'on-insurance',
                label: 'Assurance',
                disabled: true,
            },
            {
                name: 'on-civil-prop-insurance',
                label: 'Assurance (biens)',
            },
            {
                name: 'on-civil-health-insurance',
                label: 'Assurance (personne)',
            },
            {
                name: 'on-consumer',
                label: 'Consommation',
            },
            {
                name: 'on-contracts',
                label: 'Contrats, vente et louage',
            },
            {
                name: 'on-civil-banking',
                label: 'Bancaire',
            },
            {
                name: 'on-other',
                label: 'Autre/Ne sais pas',
                icon: Category,
                topLevel: true,
                children: null,
            },
            {
                name: 'on-civil-small-claims',
                label: 'Cour des petites créances',
                paralegal: true,
                coins: {
                    normal: 30,
                },
            },
        ],
    },
]

export const lawyerTypes = [
    {
        name: 'family',
        label: 'Famille et jeunesse',
        jh: true,
        icon: People,
        coins: {
            commercial: 47,
            normal: 32,
            smallMandate: 20,
            juridicalHelp: 4,
        },
        children: [
            {
                name: 'family-family',
                label: 'Famille (non constestée)',
                consultDefault: true,
                neolegalDefault: false,
                defaultBlockType: SMALL,
                coins: {
                    normal: 30,
                },
            },
            {
                name: 'family-contested',
                label: 'Famille (contesté)',
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'family-youth',
                label: 'Jeunesse',
                defaultBlockType: SMALL,
                consultDefault: true,
            },
            {
                name: 'family-mediation',
                label: 'Médiation familiale',
                defaultBlockType: SMALL,
                consultDefault: true,
                neolegalDefault: false,
                coins: {
                    normal: 30,
                },
            },
        ],
    },
    {
        name: 'business',
        label: 'Affaires',
        icon: Business,
        distanceDefault: true,
        consultDefault: true,
        neolegalDefault: false,
        defaultBlockType: COMM,
        coins: {
            commercial: 47,
            normal: 40,
            smallMandate: 20,
            juridicalHelp: 4,
        },
        children: [
            {
                name: 'business-corp',
                label: 'Corporatif',
                disabled: true,
            },
            {
                name: 'corp-start',
                label: 'Corporatif (démarrage)',
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'corp-franchise',
                label: 'Corporatif (franchisage)',
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
                coins: {
                    commercial: 70,
                },
            },
            {
                name: 'corp-ma',
                label: 'Corporatif (fusion/achat/vente/transfert)',
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
                coins: {
                    commercial: 70,
                },
            },
            {
                name: 'corp-npo',
                label: 'Corporatif (OBNL)',
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'corp-reorg',
                label: 'Corporatif (réorganisation)',
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'corp-tech',
                label: 'Corporatif (technologie)',
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'business-litige',
                label: 'Litige',
                disabled: true,
            },
            {
                name: 'litige-action',
                label: 'Litige (action sur compte)',
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'litige-competition',
                label: 'Litige (concurrence déloyale)',
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'litige-shareholders',
                label: 'Litige (entre actionnaires)',
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'litige-post-transac',
                label: 'Litige (post-transaction)',
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
                coins: {
                    commercial: 70,
                },
            },
            {
                name: 'litige-other',
                label: 'Litige (autre)',
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'commercial',
                label: 'Commercial (contractuel)',
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'arbitrage',
                label: 'Arbitrage et médiation commerciale',
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'ip',
                label: 'Propriété intellectuelle',
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'corp-minutes',
                label: 'Livres des minutes',
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
        ],
    },
    {
        name: 'criminal',
        label: 'Criminel et pénal',
        jh: true,
        icon: LockOpen,
        distanceDefault: false,
        consultDefault: true,
        neolegalDefault: false,
        coins: {
            commercial: 47,
            normal: 40,
            smallMandate: 20,
            juridicalHelp: 4,
        },
        children: [
            {
                name: 'criminal-crim',
                label: 'Criminel',
                disabled: true,
            },
            {
                name: 'crim-firearm',
                label: 'Criminel (arme à feu)',
                increases: {
                    regions: ['MTL', 'LAU', 'MON', 'LAN', 'LAV'],
                    multiplier: 1.1,
                },
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'crim-person',
                label: 'Criminel (contre la personne et la réputation)',
                increases: {
                    regions: ['MTL', 'LAU', 'MON', 'LAN', 'LAV'],
                    multiplier: 1.1,
                },
                distanceDefault: false,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'crim-prop',
                label: 'Criminel (contre les droits de propriété)',
                increases: {
                    regions: ['MTL', 'LAU', 'MON', 'LAN', 'LAV'],
                    multiplier: 1.1,
                },
                distanceDefault: false,
                consultDefault: true,
                neolegalDefault: false,
            },

            {
                name: 'theft-5000-less',
                label: 'Criminel (vol/fraude - de 5 K)',
                increases: {
                    regions: ['MTL', 'LAU', 'MON', 'LAN', 'LAV'],
                    multiplier: 1.1,
                },
                distanceDefault: false,
                consultDefault: true,
                neolegalDefault: false,
                defaultBlockType: SMALL,
            },
            {
                name: 'theft-5000-more',
                label: 'Criminel (vol/fraude + de 5 K)',
                increases: {
                    regions: ['MTL', 'LAU', 'MON', 'LAN', 'LAV'],
                    multiplier: 1.1,
                },
                distanceDefault: false,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'crim-fraud',
                label: 'Criminel (fraude)',
                increases: {
                    regions: ['MTL', 'LAU', 'MON', 'LAN', 'LAV'],
                    multiplier: 1.1,
                },
                consultDefault: true,
            },
            {
                name: 'crim-youth',
                label: 'Criminel (jeune contrevenant)',
                increases: {
                    regions: ['MTL', 'LAU', 'MON', 'LAN', 'LAV'],
                    multiplier: 1.1,
                },
                consultDefault: true,
            },
            {
                name: 'crim-transport',
                label: 'Criminel (moyen de transport)',
                increases: {
                    regions: ['MTL', 'LAU', 'MON', 'LAN', 'LAV'],
                    multiplier: 1.1,
                },
                consultDefault: true,
                distanceDefault: true,
            },
            {
                name: 'crim-waiver',
                label: 'Criminel (pardon/waiver)',
                increases: {
                    regions: ['MTL', 'LAU', 'MON', 'LAN', 'LAV'],
                    multiplier: 1.1,
                },
                consultDefault: true,
                distanceDefault: true,
                defaultBlockType: SMALL,
            },
            {
                name: 'crim-sex',
                label: 'Criminel (sexuel)',
                increases: {
                    regions: ['MTL', 'LAU', 'MON', 'LAN', 'LAV'],
                    multiplier: 1.1,
                },
                consultDefault: true,
                distanceDefault: true,
            },
            {
                name: 'crim-stup',
                label: 'Criminel (stupéfiant)',
                increases: {
                    regions: ['MTL', 'LAU', 'MON', 'LAN', 'LAV'],
                    multiplier: 1.1,
                },
                consultDefault: true,
                distanceDefault: true,
            },
            {
                name: 'crim-murder',
                label: 'Criminel (homicide/meurtre)',
                increases: {
                    regions: ['MTL', 'LAU', 'MON', 'LAN', 'LAV'],
                    multiplier: 1.1,
                },
                consultDefault: true,
                distanceDefault: true,
                coins: {
                    normal: 120,
                },
            },
            {
                name: 'criminal-penal',
                label: 'Pénal',
                disabled: true,
                consultDefault: true,
            },
            {
                name: 'penal-hunting',
                label: 'Pénal (chasse)',
                consultDefault: true,
                defaultBlockType: SMALL,
            },
            {
                name: 'penal-enterprise',
                label: 'Pénal (entreprise)',
                consultDefault: true,
                defaultBlockType: COMM,
            },
            {
                name: 'penal-environment',
                label: 'Pénal (environnement)',
                consultDefault: true,
                defaultBlockType: SMALL,
            },
            {
                name: 'penal-road',
                label: 'Pénal (infraction de la route)',
                defaultBlockType: SMALL,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'penal-other',
                label: 'Pénal (autre)',
                defaultBlockType: SMALL,
                consultDefault: true,
            },
        ],
    },
    {
        name: 'realestate',
        label: 'Immobilier',
        icon: House,
        consultDefault: true,
        neolegalDefault: false,
        coins: {
            commercial: 47,
            normal: 40,
            smallMandate: 20,
            juridicalHelp: 4,
        },
        children: [
            {
                name: 'construction',
                label: 'Construction',
                consultDefault: true,
                neolegalDefault: false,
                distanceDefault: true,
            },
            {
                name: 'condo',
                label: 'Copropriété',
                defaultBlockType: SMALL,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'realestate-title',
                label: 'Titre, bornage, servitude, etc',
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'realestate-vice',
                label: 'Vice caché',
                consultDefault: true,
                neolegalDefault: false,
                distanceDefault: true,
            },
            {
                name: 'realestate-transaction',
                label: 'Achat/Vente',
                consultDefault: true,
                neolegalDefault: false,
                distanceDefault: true,
            },
        ],
    },
    {
        name: 'immigration',
        label: 'Immigration et citoyenneté',
        icon: FlightLand,
        consultDefault: true,
        distanceDefault: true,
        coins: {
            commercial: 47,
            normal: 30,
            smallMandate: 20,
            juridicalHelp: 4,
        },
        children: [
            {
                name: 'immigration-sponsorship',
                label: 'Parrainage',
                consultDefault: true,
                distanceDefault: true,
            },
            {
                name: 'immigration-work-permit',
                label: `Permis d'étude et de travail`,
                consultDefault: true,
                distanceDefault: true,
            },
            {
                name: 'immigration-refugee',
                label: 'Réfugié',
                consultDefault: true,
                distanceDefault: true,
                defaultBlockType: SMALL,
            },
            {
                name: 'immigration-permanent',
                label: 'Résidence permanente ',
                consultDefault: true,
                distanceDefault: true,
            },
            {
                name: 'immigration-contest',
                label: 'Contestation (contrôle et appel)',
                consultDefault: true,
                distanceDefault: true,
            },
            {
                name: 'immigration-agency',
                label: 'Agence internationale de recrutement/placement',
                consultDefault: true,
                distanceDefault: true,
                defaultBlockType: COMM,
                coins: {
                    commercial: 94,
                },
            },
            {
                name: 'immigration-employer',
                label: 'Employeur',
                consultDefault: true,
                distanceDefault: true,
                defaultBlockType: COMM,
            },
            {
                name: 'immigration-other',
                label: 'Autre',
                consultDefault: true,
                distanceDefault: true,
            },
        ],
    },
    {
        name: 'admin',
        jh: true,
        label: 'Administratif',
        icon: Description,
        distanceDefault: true,
        consultDefault: true,
        neolegalDefault: false,
        coins: {
            commercial: 47,
            normal: 30,
            smallMandate: 20,
            juridicalHelp: 4,
        },
        children: [
            {
                name: 'admin-social',
                label: 'Aide sociale / chômage',
                defaultBlockType: SMALL,
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'admin-cnesst',
                label: 'CNESST',
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'admin-cptaq',
                label: 'CPTAQ',
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'admin-saaq',
                label: 'SAAQ (administratif)',
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
                defaultBlockType: SMALL,
            },
            {
                name: 'admin-saaq-accident',
                label: 'SAAQ (accident/blessure)',
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'admin-ivac',
                label: 'IVAC',
                defaultBlockType: SMALL,
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'admin-rentes',
                label: 'Rentes / Pension',
                defaultBlockType: SMALL,
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'admin-scolaire',
                label: 'Scolaire',
                defaultBlockType: SMALL,
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'admin-transport',
                label: 'Transport',
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'admin-autres',
                label: 'Autres',
                defaultBlockType: SMALL,
                distanceDefault: true,
                consultDefault: true,
                neolegalDefault: false,
            },
        ],
    },
    {
        name: 'responsability',
        label: 'Responsabilité civile',
        icon: AssignmentLate,
        consultDefault: true,
        coins: {
            commercial: 47,
            normal: 40,
            smallMandate: 20,
            juridicalHelp: 4,
        },
        children: [
            {
                name: 'responsability-general',
                label: 'Générale',
                consultDefault: true,
                neolegalDefault: false,
                defaultBlockType: SMALL,
            },
            {
                name: 'responsability-slander',
                label: 'Diffamation / atteinte à la réputation',
                defaultBlockType: SMALL,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'responsability-injury',
                label: 'Blessure corporelle',
                consultDefault: true,
                distanceDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'responsability-medical',
                label: 'Erreur médicale',
                consultDefault: true,
                distanceDefault: true,
            },
            {
                name: 'responsability-pro',
                label: 'Professionnelle et disciplinaire',
                consultDefault: true,
                distanceDefault: true,
            },
            {
                name: 'responsability-sexual',
                label: 'Sexuel',
                consultDefault: true,
                distanceDefault: true,
            },
            {
                name: 'responsability-neighborhood',
                label: 'Trouble de voisinage',
                defaultBlockType: SMALL,
                consultDefault: true,
                neolegalDefault: false,
            },
        ],
    },
    {
        name: 'work',
        label: 'Travail',
        icon: Work,
        consultDefault: true,
        distanceDefault: true,
        coins: {
            commercial: 47,
            normal: 40,
            smallMandate: 20,
            juridicalHelp: 4,
        },
        children: [
            {
                name: 'work-employee',
                label: 'Harcèlement / pratiques interdites',
                consultDefault: true,
                defaultBlockType: SMALL,
                coins: {
                    normal: 30,
                },
            },
            {
                name: 'work-employer',
                label: 'Employeur',
                defaultBlockType: COMM,
                increases: {
                    regions: ['MTL', 'LAU', 'MON', 'LAN', 'LAV'],
                    multiplier: 1.1,
                },
                consultDefault: true,
                distanceDefault: true,
                coins: {
                    commercial: 70,
                },
            },
            {
                name: 'work-termination',
                label: 'Congédiement (salarié)',
                consultDefault: true,
            },
            {
                name: 'work-termination-exec',
                label: 'Congédiement (cadre supérieur)',
                consultDefault: true,
                distanceDefault: true,
                coins: {
                    normal: 60,
                },
            },
        ],
    },
    {
        name: 'civil',
        label: 'Civil (général)',
        icon: Assignment,
        consultDefault: true,
        neolegalDefault: false,
        coins: {
            commercial: 47,
            normal: 30,
            smallMandate: 20,
            juridicalHelp: 4,
        },
        children: [
            {
                name: 'colAction',
                label: 'Action collective',
                consultDefault: true,
                distanceDefault: true,
                coins: {
                    normal: 80,
                },
            },
            {
                name: 'civil-account',
                label: 'Action sur compte',
                defaultBlockType: SMALL,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'civil-prop-insurance',
                label: 'Assurance (biens)',
                consultDefault: true,
                distanceDefault: true,
                defaultBlockType: SMALL,
            },
            {
                name: 'civil-health-insurance',
                label: 'Assurance (personne)',
                consultDefault: true,
                distanceDefault: true,
            },
            {
                name: 'consumer',
                label: 'Consommation',
                defaultBlockType: SMALL,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'contracts',
                label: 'Contrats, vente et louage',
                defaultBlockType: SMALL,
                consultDefault: true,
                neolegalDefault: false,
            },
            {
                name: 'civil-banking',
                label: 'Bancaire',
                defaultBlockType: SMALL,
                consultDefault: true,
            },
            {
                name: 'civil-other',
                label: 'Autre',
                defaultBlockType: SMALL,
                consultDefault: true,
            },
        ],
    },

    {
        name: 'fiscal',
        label: 'Fiscalité et taxes',
        icon: FolderOpen,
        distanceDefault: true,
        coins: {
            commercial: 47,
            normal: 30,
            smallMandate: 20,
            juridicalHelp: 4,
        },
        children: [
            {
                name: 'fiscal-enterprise',
                label: 'Entreprise',
                distanceDefault: true,
                defaultBlockType: COMM,
            },
            {
                name: 'fiscal-person',
                label: 'Particulier',

                distanceDefault: true,
            },
        ],
    },

    {
        name: 'rental',
        label: 'Logement',
        icon: Apartment,
        jh: true,
        consultDefault: true,
        defaultBlockType: SMALL,
        coins: {
            commercial: 47,
            normal: 30,
            smallMandate: 20,
            juridicalHelp: 4,
        },
        children: [
            {
                name: 'rental-landlord',
                label: 'Propriétaire',
                distanceDefault: true,
                consultDefault: true,
                defaultBlockType: MP,
            },
            {
                name: 'rental-renter',
                label: 'Locataire',
                defaultBlockType: SMALL,
                consultDefault: true,
            },
        ],
    },
    {
        name: 'succession',
        label: 'Succession',
        children: null,
        topLevel: true,
        icon: Cached,
        consultDefault: true,
        distanceDefault: true,
        coins: {
            commercial: 47,
            normal: 40,
            smallMandate: 20,
            juridicalHelp: 4,
        },
        children: [
            {
                name: 'estate-admin',
                label: 'Administration de succession',
                consultDefault: true,
                distanceDefault: true,
            },
            {
                name: 'estate-litigation',
                label: 'Litige successoral',
                consultDefault: true,
                distanceDefault: true,
            },
            {
                name: 'estate-wills',
                label: 'Testaments',
                consultDefault: true,
                distanceDefault: true,
                defaultBlockType: SMALL,
            },
        ],
    },
    {
        name: 'rights',
        label: 'Droits et libertés',
        jh: true,
        icon: EmojiPeople,
        consultDefault: true,
        coins: {
            commercial: 47,
            normal: 30,
            smallMandate: 20,
            juridicalHelp: 4,
        },
        children: [
            {
                name: 'rights-liberty',
                label: 'Droits et libertés',
                disabled: true,
            },
            {
                name: 'rights-native',
                label: 'Autochtone',
                defaultBlockType: SMALL,
                consultDefault: true,
            },
            {
                name: 'rights-constitution',
                label: 'Constitution et chartes',
                consultDefault: true,
            },
            {
                name: 'rights-protection',
                label: 'Personnes vulnérables (curatelle, tutelle, conseil au majeur)',
                defaultBlockType: SMALL,
                consultDefault: true,
            },
            {
                name: 'rights-care',
                label: 'Soin et garde en établissement',
                defaultBlockType: SMALL,
                consultDefault: true,
            },
        ],
    },
    {
        name: 'bankruptcy',
        label: 'Faillite et insolvabilité',
        icon: CreditCard,
        consultDefault: true,
        coins: {
            commercial: 47,
            normal: 30,
            smallMandate: 20,
            juridicalHelp: 4,
        },
        children: [
            {
                name: 'bankruptcy-enterprise',
                label: 'Entreprise',
                consultDefault: true,
                distanceDefault: true,
                defaultBlockType: COMM,
            },
            {
                name: 'bankruptcy-person',
                label: 'Particulier',
                consultDefault: true,
            },
        ],
    },
    {
        name: 'municipal',
        label: 'Municipal',
        icon: AccountBalance,
        children: null,
        topLevel: true,
        consultDefault: true,
        distanceDefault: true,
        coins: {
            commercial: 47,
            normal: 30,
            smallMandate: 20,
            juridicalHelp: 4,
        },
    },

    {
        name: 'other',
        label: 'Autre/Ne sais pas',
        icon: Category,
        topLevel: true,
        children: null,
        coins: {
            commercial: 47,
            normal: 40,
            smallMandate: 20,
            juridicalHelp: 4,
        },
    },
]

export default lawyerTypes

export const checkIfParentOf = (possibleParent, possibleChild) => {
    if (
        flatMap[possibleChild] &&
        flatMap[possibleChild].parent === possibleParent
    ) {
        return true
    }
    return false
}

export const adminLawyerTypes = (function () {
    const adminTypes = []
    lawyerTypes.forEach((type) => {
        if (type.children) {
            adminTypes.push(...type.children)
        } else {
            adminTypes.push(type)
        }
    })
    return adminTypes
})()

export const allLawyerTypes = (function () {
    const adminTypes = []
    lawyerTypes.forEach((type) => {
        adminTypes.push({
            name: type.name,
            label: type.label,
            parent: true,
        })

        if (type.children) {
            adminTypes.push(...type.children)
        }
    })
    return adminTypes
})()

export const userLawyerTypesMappings = (function () {
    const mappings = {}
    lawyerTypes.forEach((type) => {
        mappings[type.label] = type.name
    })
    return mappings
})()

function mapTypes(types, newMap) {
    types.forEach((type) => {
        newMap[type.name] = type.label
        if (type.children) {
            type.children.forEach((childType) => {
                newMap[childType.name] = childType.label
            })
        }
    })
}

export const lawyerTypesMappings = (function () {
    const mappings = {}
    mapTypes(lawyerTypes, mappings)
    mapTypes(lawyerTypesON, mappings)
    return mappings
})()

export const lawyerTypesAdminMappings = (function () {
    const mappings = {}
    lawyerTypes.forEach((type) => {
        if (type.children) {
            type.children.forEach((childType) => {
                mappings[childType.name] = childType.label
            })
        } else {
            mappings[type.name] = type.label
        }
    })
    return mappings
})()

const flatMap = (function () {
    const mappings = {}
    lawyerTypes.forEach((type) => {
        if (type.children) {
            type.children.forEach((childType) => {
                mappings[childType.name] = {
                    ...childType,
                    parent: type.name,
                    jh: type.jh,
                }
            })
            mappings[type.name] = type
        } else {
            mappings[type.name] = type
        }
    })
    lawyerTypesON.forEach((type) => {
        if (type.children) {
            type.children.forEach((childType) => {
                mappings[childType.name] = {
                    ...childType,
                    parent: type.name,
                    jh: type.jh,
                }
            })
            mappings[type.name] = type
        } else {
            mappings[type.name] = type
        }
    })
    return mappings
})()

export const checkBlockType = (revenueCode, juridicalHelp, lawyerType) => {
    const lawyerTypeDetails = lawyerTypesFlatMap[lawyerType]
    if (!lawyerTypeDetails) {
        return MP
    }
    if (juridicalHelp) {
        if (lawyerTypeDetails?.jh && juridicalHelp) {
            return LEGAL_AID
        }
    }
    if (lawyerTypeDetails.defaultBlockType) {
        return lawyerTypeDetails.defaultBlockType
    }
    if (
        lawyerTypeDetails.parent &&
        lawyerTypesFlatMap[lawyerTypeDetails.parent].defaultBlockType
    ) {
        return lawyerTypesFlatMap[lawyerTypeDetails.parent].defaultBlockType
    }

    return MP
}

export const lawyerTypesFlatMap = flatMap
