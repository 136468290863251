const phoneNumberReg = new RegExp(
    /^[2-9]{1}[0-9]{2}(-|\s)?[2-9]{1}[0-9]{2}(-|\s)?[0-9]{4}$/
)
const emailReg = new RegExp(
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
)

const simpleEmailReg = new RegExp(/.{1,}@.{1,}[.]{1}.{1,}/)

export default {
    clientName: {
        valid: undefined,
        value: '',
    },
    email: {
        valid: undefined,
        value: '',
        regex: simpleEmailReg,
    },
    othersInvolved: {
        valid: undefined,
        value: '',
    },
    phoneNumber: {
        valid: undefined,
        value: '',
    },
    caseDescription: {
        valid: undefined,
        value: '',
    },
    province: {
        valid: undefined,
        value: '',
    },
    region: {
        valid: undefined,
        value: '',
    },
    acceptDistance: {
        value: undefined,
        optional: true,
        valid: undefined,
    },
    hasCourtDate: {
        value: '',
        optional: true,
        valid: undefined,
    },
    courtDate: {
        value: undefined,
        optional: true,
        valid: undefined,
    },
    hasCriminalFile: {
        value: '',
        optional: true,
        valid: undefined,
    },
    employementPeril: {
        value: '',
        optional: true,
        valid: undefined,
    },
    goal: {
        value: '',
        optional: true,
        valid: undefined,
    },
    meetAsap: {
        value: undefined,
        optional: true,
        valid: undefined,
    },
    acceptEnglishLawyer: {
        value: undefined,
        optional: true,
        valid: undefined,
    },
    bestTimeToContact: {
        value: [],
        optional: true,
        valid: undefined,
    },
}
